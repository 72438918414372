import React from "react";
 
const Footer = () => {
    return (
        <footer
            style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                textAlign: "center",
                backgroundColor: " #FFFFFF",
                borderTop:"1px solid #EEE",
                padding: "10px",
            }}
        >
            <span
                className="text-blue-900 mt-3 font-bold"
                style={{ color: "#1e3a8a" }}
            >
        CONFIDENTIAL | NOT FOR GENERAL DISTRIBUTION © Imenso Software | ALL
        RIGHTS RESERVED | All trademarks and IPs are acknowledged to be the property of their respective owners
            </span>
        </footer>
    );
};
 
export default Footer;
 
 